<template>
  <div>
    <div class="chart-bg p-3">
      <b-row class="chart-gap">
        <b-col md="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="d-flex justify-content-between mb-3">
              <div class="chart-title-name">Total Customer By Date</div>
              <div class="d-flex" style="column-gap: 5px">
                <div
                  v-for="item in dateFilterList"
                  :key="item.value"
                  :class="`f-14 underline cursor-pointer`"
                >
                  <span
                    :class="
                      filters.TypeDateFilter == item.value && 'text-highlight'
                    "
                    class="text-underline text-filter"
                    @click="filterGraph(item.value)"
                  >
                    {{ item.text }}
                  </span>
                </div>
                <div :class="`f-14 underline cursor-pointer `">
                  <span
                    class="text-highlight"
                    @click="exportExcel(1, 'total-customer-by-date')"
                    >Export</span
                  >
                </div>
              </div>
            </div>

            <Chart
              id="customer-by-date-chart"
              :chartData="chartTotal"
              text="Percent Of Customers"
              class="mt-auto"
              :label="chartTotalLabel"
              :hideLegend="false"
              v-if="!isChartLoading"
              :customTooltipTitle="customTitle"
              :customTooltipLabel="customLabel"
              type="bar"
              usePercent
            />

            <ChartLoading v-else :id="'loading'" type="bar" />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="card-shadow border-none text-black chart-rounded">
            <div>
              <div class="content-between mb-2">
                <div class="chart-title-name">Age</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportExcel(2, 'ReportProspectAgeGroup')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <Chart
                id="age-chart"
                :chartData="chartAge"
                text="Percent Of Responses"
                class="mt-auto"
                :label="chartAgeLabel"
                :hideLegend="false"
                v-if="!isChartAgeLoading"
                :customTooltipTitle="customTitle"
                type="bar"
                :customTooltipLabel="customLabel"
                usePercent
              />
              <ChartLoading v-else :id="'loading-age'" type="bar" />
            </div>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="card-shadow border-none text-black chart-rounded">
            <div>
              <div class="content-between mb-2">
                <div class="chart-title-name">Gender</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportExcel(3, 'ReportProspectGender')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>

              <Chart
                id="gender-chart"
                :chartData="chartGender"
                text="Percent Of Responses"
                class="mt-auto"
                :label="chartGenderLabel"
                :hideLegend="false"
                v-if="!isChartGenderLoading"
                :customTooltipTitle="customTitle"
                type="bar"
                :customTooltipLabel="customLabel"
                usePercent
              />
              <ChartLoading v-else :id="'loading-gender'" type="bar" />
            </div>
          </b-card>
        </b-col>
        <b-col v-for="(item, index) in customFieldChart" :key="index" md="6">
          <b-card class="card-shadow border-none text-black chart-rounded">
            <div>
              <div class="content-between mb-2">
                <div class="chart-title-name">{{ item.name }}</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="
                        exportExcel(4, `ReportProspect${item.name}`, item.id)
                      "
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <Chart
                :id="item.id.toString()"
                :chartData="item.charts"
                text="Percent Of Responses"
                class="mt-auto"
                :label="customFiedlLabel[index]"
                :customTooltipTitle="customTitle"
                type="bar"
                :customTooltipLabel="customLabel"
                usePercent
                hideLegend
              />
              <!-- <ChartLoading v-else :id="'loading'" type="bar" /> -->
            </div>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card class="card-shadow border-none text-black chart-rounded">
            <div class="d-flex justify-content-between">
              <span class="chart-title-name">Province</span>
              <span
                class="text-highlight f-14 underline cursor-pointer"
                @click="exportExcel(5, 'ReportProvince')"
                >Export</span
              >
            </div>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              @sort-changed="sortingChanged"
              :sort-by.sync="filters.sortBy"
              no-local-sorting
              :sort-desc.sync="filters.sortDesc"
              foot-clone
              no-footer-sorting
            >
              <template v-slot:cell(value)="data">
                <span>
                  {{ data.item.value | numeral("0,0") }} ({{
                    data.item.percent
                  }}%)
                </span>
              </template>
              <template v-slot:foot()="data">
                <span v-if="data.field.key == 'value'">
                  {{ subTotal.total | numeral("0,0") }}
                </span>
                <span v-else>
                  {{ data.field.text }}
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeProduct"
              @pagination="paginationProduct"
              :filter="filters"
              :rows="rows"
              :limit="2"
          /></b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Chart from "chart";
import ChartLoading from "@/components/loading/ChartLoading";

export default {
  components: { Chart, ChartLoading },
  props: {
    tabIndex: {
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "group",
          label: "Province",
          sortable: true,
          text: "Total",
          class: "text-left w-50 ",
        },
        {
          key: "value",
          label: "Value",
          sortable: true,
          class: "text-left w-50 ",
        },
      ],
      filters: {
        RegisterDateFrom: this.$store.state.filter.from,
        RegisterDateTo: this.$store.state.filter.to,
        Search: "",
        PageNumber: 1,
        RecordPerPage: 10,
        SortColumnId: 0,
        SortType: false, // true = ASC, flase = DESC
        TypeDateFilter: 2,
        take: 10,
        page: 1,
        sortDesc: "",
        sortBy: "",
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      },
      isBusy: false,
      statColumn: [
        {
          name: "Total",
          key: "total",
          isPercent: false,
          isDecimal: false,
        },
      ],
      subTotal: {
        total: 0,
        percent: 0,
      },
      statLoading: true,
      dateFilterList: [
        {
          value: 3,
          text: "Day",
        },
        {
          value: 2,
          text: "Month",
        },
        {
          value: 1,
          text: "Year",
        },
      ],
      chartTotal: [],
      isChartLoading: true,
      chartTotalLabel: [],
      chartAge: [],
      chartAgeLabel: [],
      isChartAgeLoading: true,
      chartGender: [],
      chartGenderLabel: [],
      isChartGenderLoading: true,
      customFieldChart: [],
      customFiedlLabel: [],
      iscustomFieldLoading: true,
      items: [],
      rows: 0,
      register_form_field_id: 0,
    };
  },

  methods: {
    async getData(filter) {
      this.statLoading = true;
      if (filter) {
        this.filters.Search = filter.Search;
        this.filters.RegisterDateFrom = filter.RegisterDateFrom;
        this.filters.RegisterDateTo = filter.RegisterDateTo;
      }
      let payload = { ...this.filters };

      if (payload.RegisterDateFrom) {
        payload.RegisterDateFrom = this.$moment(
          payload.RegisterDateFrom
        ).format("YYYY-MM-DD");
      }
      if (payload.RegisterDateTo) {
        payload.RegisterDateTo = this.$moment(payload.RegisterDateTo).format(
          "YYYY-MM-DD"
        );
      }
      this.getCustomfiled();
      this.getTableProvince();
      const res = await this.$report.post(
        `/dashboard/prospect/default`,
        payload
      );
      this.subTotal.total = res.data.detail.total.total;
      this.subTotal.percent = res.data.detail.total.percentCustomer;
      this.statLoading = false;
      this.getCustomerByDateChart(res.data.detail.customerByDate);
      this.getAgeGroup(res.data.detail.ageGroup);
      this.getGenderGroup(res.data.detail.genderGroup);
    },
    async getCustomerByDateChart(data) {
      this.isChartLoading = true;
      var respData = data;
      let payload = { ...this.filters };
      if (!data) {
        const res = await this.$report.post(
          `/dashboard/prospect/customerbydate`,
          payload
        );
        respData = res.data.detail;
      }

      this.chartTotalLabel = await respData.map((el) => el.group);
      this.chartTotal = [
        {
          label: "Percent of Customers",
          type: "bar",
          data: await respData.map((el) => el.percent),
          value: await respData.map((el) => el.value),
          backgroundColor: await this.$store.dispatch("getChartColor", 0),
          fill: false,
        },
      ];

      this.isChartLoading = false;
    },
    async getAgeGroup(data) {
      this.isChartAgeLoading = true;
      var respData = data;
      let payload = { ...this.filters };

      if (!data) {
        const res = await this.$report.post(
          `/dashboard/prospect/ageGroup`,
          payload
        );
        respData = res.data.detail;
      }

      this.chartAgeLabel = await respData.map((el) => el.group);
      this.chartAge = [
        {
          label: "Percent Of Responses",
          type: "bar",
          data: await respData.map((el) => el.percent),
          value: await respData.map((el) => el.value),
          backgroundColor: await this.$store.dispatch("getChartColor", 0),
          fill: false,
        },
      ];
      this.isChartAgeLoading = false;
    },
    async getGenderGroup(data) {
      this.isChartGenderLoading = true;
      var respData = data;
      let payload = { ...this.filters };

      if (!data) {
        const res = await this.$report.post(
          `/dashboard/prospect/gender`,
          payload
        );
        respData = res.data.detail;
      }

      this.chartGenderLabel = await respData.map((el) => el.group);
      this.chartGender = [
        {
          label: "Percent Of Responses",
          type: "bar",
          data: await respData.map((el) => el.percent),
          value: await respData.map((el) => el.value),
          backgroundColor: await this.$store.dispatch("getChartColor", 0),
          fill: false,
        },
      ];
      this.isChartGenderLoading = false;
    },
    async getCustomfiled() {
      this.customFieldChart = [];
      this.iscustomFieldLoading = true;
      let payload = { ...this.filters };

      if (payload.RegisterDateFrom) {
        payload.RegisterDateFrom = this.$moment(
          payload.RegisterDateFrom
        ).format("YYYY-MM-DD");
      }
      if (payload.RegisterDateTo) {
        payload.RegisterDateTo = this.$moment(payload.RegisterDateTo).format(
          "YYYY-MM-DD"
        );
      }
      const res = await this.$report.post(
        `/dashboard/prospect/customfield`,
        payload
      );

      var respData = res.data.detail.filter((x) => x.data.length > 0);

      this.customFiedlLabel = await respData.map((field) =>
        field.data.map((item) => item.group)
      );

      let color = await this.$store.dispatch("getChartColor", 0);

      this.customFieldChart = await respData.map((field, index) => ({
        id: field.register_form_field_id,
        name: field.field_name,
        charts: [
          {
            label: "Percent of Customer",
            data: field.data.map((item) => item.percent),
            value: field.data.map((item) => item.value),
            backgroundColor: color,
          },
        ],
      }));

      this.iscustomFieldLoading = false;
    },
    async getTableProvince() {
      this.isBusy = true;
      let payload = { ...this.filters };
      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;

      if (payload.RegisterDateFrom) {
        payload.RegisterDateFrom = this.$moment(
          payload.RegisterDateFrom
        ).format("YYYY-MM-DD");
      }
      if (payload.RegisterDateTo) {
        payload.RegisterDateTo = this.$moment(payload.RegisterDateTo).format(
          "YYYY-MM-DD"
        );
      }

      const res = await this.$report.post(
        `/dashboard/prospect/province`,
        payload
      );
      var respData = res.data.detail;
      this.items = respData.data;
      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    sortingChanged(ctx) {
      this.isBusy = true;
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filters.SortType &&
        !ctx.sortDesc &&
        this.filters.SortColumnId == index
      ) {
        this.filters.SortColumnId = 0;
        this.filters.sortBy = "";
        this.filters.sortDesc = "";
      } else {
        this.filters.SortColumnId = index;
        this.filters.SortType = ctx.sortDesc;
      }
      this.getTableProvince();
      this.isBusy = false;
    },
    paginationProduct(val) {
      this.filters.page = val;
      this.getTableProvince();
    },
    handleChangeTakeProduct(val) {
      this.filters.take = val;
      this.filters.page = 1;
      this.getTableProvince();
    },
    filterGraph(val) {
      this.filters.TypeDateFilter = val;
      this.getCustomerByDateChart();
    },
    async exportExcel(reportType, name, customFiled_id) {
      try {
        let body = {
          ...this.filters,
          register_form_field_id: customFiled_id,
          reportType: reportType,
        };

        this.$bus.$emit("showLoading");

        const res = await this.$report.post(
          `/dashboard/prospect/report`,
          body,
          {
            responseType: "blob",
          }
        );
        let data = res;
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
        body = {};
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    customLabel(tooltipItem, data) {
      // if (tooltipItem.value > 0) {
      var datasetLabel =
        data.datasets[tooltipItem.datasetIndex].label || "Other";
      if (data.datasets[tooltipItem.datasetIndex].value) {
        let numData =
          data.datasets[tooltipItem.datasetIndex].value[tooltipItem.index];
        return `${datasetLabel} : ${numData} (${tooltipItem.value}%)`;
      } else {
        return `${datasetLabel} :${tooltipItem.value}`;
      }
      // }
    },
    customTitle(tooltipItem, data) {
      return `${data.labels[tooltipItem[0].index]}`;
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1 !important;
  text-decoration: underline;
}

.text-filter {
  opacity: 0.5;
}
</style>
