<template>
  <div class="chart-gap">
    <b-card class="card-shadow border-none text-black chart-rounded">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
        @sort-changed="sortingChanged"
        :sort-by.sync="filters.sortBy"
        no-local-sorting
        :sort-desc.sync="filters.sortDesc"
      >
        <template v-slot:cell(first_name_th)="data">
          <div>{{ data.item.first_name_th }} {{ data.item.last_name_th }}</div>
        </template>
        <template v-slot:cell(telephone)="data">
          <div>{{ convertTelePhone(data.item.telephone) }}</div>
        </template>
        <template v-slot:cell(member)="data">
          <span>
            {{ data.item.member | numeral("0,0.00") }}
            ({{ data.item.percen_member }}%)
          </span>
        </template>
        <template v-slot:cell(birthday)="data">
          <span>
            {{ data.item.birthday || "N/A" }}
          </span>
        </template>
        <template v-slot:cell(age)="data">
          <span>
            {{ data.item.age || "-" }}
          </span>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTakeProduct"
        @pagination="paginationProduct"
        :filter="filters"
        :rows="rows"
        :limit="2"
    /></b-card>
  </div>
</template>

<script>
export default {
  emits: ["daTaTotal"],
  data() {
    return {
      fields: [
        {
          key: "member_id",
          label: "Member ID",
          sortable: true,
        },
        {
          key: "first_name_th",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",

          sortable: true,
        },
        {
          key: "telephone",
          label: "Telephone",

          sortable: true,
        },
        {
          key: "birthday",
          label: "Birthday",

          sortable: true,
        },
        {
          key: "age",
          label: "Age",
          class: "w-f-80px",
          sortable: true,
        },
        {
          key: "gender",
          label: "Gender",

          sortable: true,
        },
        {
          key: "consent_status",
          label: "Consent Status",

          sortable: true,
        },
        {
          key: "register_date",
          label: "Registered Date",
          class: "w-f-80px",
          sortable: true,
        },
      ],
      rows: 0,
      items: [],
      filters: {
        Search: "",
        RegisterDateFrom: this.$store.state.filter.from,
        RegisterDateTo: this.$store.state.filter.to,
        PageNumber: 1,
        RecordPerPage: 15,
        SortColumnId: 0,
        take: 10,
        page: 1,
        sortBy: "",
        sortDesc: "",
        SortType: false, // true = ASC, flase = DESC
        allow_anonymous: this.$store.state.filter.allow_anonymous,
      },
      stat: {
        totalCustomer: 0,
        totalCustomerPercent: 0,
        dateStart: "",
        dateEnd: "",
      },
      isBusy: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData(filter) {
      this.isBusy = true;
      if (filter) {
        this.filters.Search = filter.Search;
        this.filters.RegisterDateFrom = filter.RegisterDateFrom;
        this.filters.RegisterDateTo = filter.RegisterDateTo;
      }

      let payload = {
        ...this.filters,
      };

      if (payload.RegisterDateFrom) {
        payload.RegisterDateFrom = this.$moment(
          payload.RegisterDateFrom
        ).format("YYYY-MM-DD");
      }
      if (payload.RegisterDateTo) {
        payload.RegisterDateTo = this.$moment(payload.RegisterDateTo).format(
          "YYYY-MM-DD"
        );
      }
      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;
      const res = await this.$report.post(`/DashBoard/prospect`, payload);
      this.items = res.data.detail.data;

      this.stat.totalCustomer = res.data.detail.total;
      this.stat.totalCustomerPercent = res.data.detail.percentCustomer;
      this.stat.dateStart = this.filters.RegisterDateFrom;
      this.stat.dateEnd = this.filters.RegisterDateTo;

      this.$emit("daTaTotal", this.stat);

      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    sortingChanged(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filters.SortType &&
        !ctx.sortDesc &&
        this.filters.SortColumnId == index
      ) {
        this.filters.SortColumnId = 0;
        this.filters.sortBy = "";
        this.filters.sortDesc = "";
      } else {
        this.filters.SortColumnId = index;
        this.filters.SortType = ctx.sortDesc;
      }
      this.getData();
    },
    paginationProduct(val) {
      this.filters.page = val;
      this.getData();
    },
    handleChangeTakeProduct(val) {
      this.filters.take = val;
      this.filters.page = 1;
      this.getData();
    },
    async exportFile() {
      let payload = { ...this.filters };
      if (payload.RegisterDateFrom) {
        payload.RegisterDateFrom = this.$moment(
          payload.RegisterDateFrom
        ).format("YYYY-MM-DD");
      }
      if (payload.RegisterDateTo) {
        payload.RegisterDateTo = this.$moment(payload.RegisterDateTo).format(
          "YYYY-MM-DD"
        );
      }
      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;
      const res = await this.$report.post(
        `/DashBoard/report/prospect`,
        payload,
        {
          responseType: "blob",
        }
      );
      let data = res;
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `ProspectReport.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    showFilter() {
      this.$refs.prospectFilter.show();
    },
  },
};
</script>

<style></style>
